export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~5],
		"/(footer)/ai": [10,[3]],
		"/api/TEST_ONLY/oauth/authorize": [17],
		"/(footer)/blog": [~11,[3]],
		"/(footer)/blog/code-review-acronyms-lgtm-nit": [~13,[3]],
		"/(footer)/blog/code-review-checklist-interactive": [~14,[3]],
		"/(footer)/blog/startup-viability-score": [~15,[3]],
		"/(footer)/blog/[slug]": [~12,[3]],
		"/docs": [18,[4]],
		"/docs/commands": [20,[4]],
		"/docs/[slug]": [~19,[4]],
		"/(footer)/imprint": [16,[3]],
		"/(app)/login": [~6,[2]],
		"/(app)/logs": [~7,[2]],
		"/(app)/review": [~8,[2]],
		"/(app)/review/[reviewId]": [~9,[2]],
		"/users": [~21]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';